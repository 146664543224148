<template>
  <div>
    <c-card title="밀폐공간작업 상세정보" class="cardClassDetailForm no-margin">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-textarea
            :disabled="!isWriting"
            :editable="editable"
            label="출입사유"
            name="closeEntryResaon"
            v-model="supWork.closeEntryResaon"
            @dataChange="dataChange">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-textarea
            :disabled="!isWriting"
            :editable="editable"
            label="밀폐장소의 예상위험"
            name="closeExpectedRisk"
            v-model="supWork.closeExpectedRisk"
            @dataChange="dataChange">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            :disabled="!isWriting"
            :editable="editable"
            :data="supWork"
            deptValue="closeGuardianDeptCd"
            type="dept_user"
            label="감시인"
            name="closeGuardianId"
            v-model="supWork.closeGuardianId"
            @dataChange="guardianChange" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            phoneNumberCheck
            :disabled="true"
            :editable="editable"
            label="감시인 전화번호"
            name="closeGuardianMobileNo"
            v-model="supWork.closeGuardianMobileNo">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <c-table
          class="q-mt-md"
          isTitle
          title="안전조치 요구사항"
          :columns="grid.columns"
          :gridHeightAuto="true"
          :data="supWork.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
        >
          <!-- @table-data-change="tableDataChange" -->
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <c-table
          ref="entrantsTable"
          class="q-mt-md"
          isTitle
          title="입출입자"
          :gridHeightAuto="true"
          :columns="gridEnter.columns"
          :data="supWork.entrants"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          selection="multiple"
          rowKey="sopWorkEntrantId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&isWriting" label="외부" icon="add" :showLoading="false" @btnClicked="addVendorEntrant" />
              <c-btn v-if="editable&&isWriting" label="내부" icon="add" :showLoading="false" @btnClicked="addEntrant" />
              <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="removeEntrant" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <c-mobile-table
          ref="gasTable"
          isTitle
          class="q-mt-md"
          title="가스농도 측정"
          :columns="grid2.columns"
          :gridHeightAuto="true"
          :data="supWork.gases"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          selection="multiple"
          rowKey="sopGasCheckId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&isWriting" label="추가" icon="add" :showLoading="false" @btnClicked="addGas" />
              <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="removeGas" />
            </q-btn-group>
          </template>
        </c-mobile-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-confined-space',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          swpStepCd: '',  // 진행단계 코드
        }
      }
    },
    supWork: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          supplementWorkTypeCd: '',  // 보충작업구분
          workProposalNo: '',  // 작업 신청번호_방사선, 고소, 중장비
          workLocation: '',  // 작업장소_방사선, 고소, 중장비
          workSummary: '',  // 작업개요_고소, 방사선,굴착, 중장비
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          relationCooperation1DeptCd: '',  // 관려부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관려부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          specialRequirements: '',  // 특별요구사항
          closeMachine: '',  // (밀폐)장치명
          closeEntryResaon: '',  // (밀폐)출입사유
          closeEntryUserIds: '',  // (밀폐)출입자id_구분자이용 복수등록
          closeEntryUserNames: '',  // (밀폐)출입자명_구분자이용 복수등록
          closeExpectedRisk: '',  // (밀폐)예상위험
          closeGuardianId: '',  // (밀폐)감시인
          closeGuardianMobileNo: '',  // (밀폐)감시인 전화번호
          blackoutCircuitBreaker: '',  // (정전)차단기기
          blackoutCircuitBreakerNo: '',  // (정전)차단기 번호
          blackoutPowerRecoveryRequestUserId: '',  // (정전)전원복구 요청자
          blackoutPowerRecoveryTime: '',  // (정전)전원복구 시간
          blackoutElectricBlockUserId: '',  // (정전)차단확인자(전기)
          blackoutMaintenanceBlockUserId: '',  // (정전)차단확인자(정비)
          radiationResponsibleUserId: '',  // (방사선)방사능 책임자
          heavyEquipSafetyCheckUserId: '',  // (중장비)안전조치 확인자
          heavyEquipManageUserId: '',  // (중장비)장비관리 책임자
          heavyEquipDriveUserId: '',  // (중장비)운전원
          heavyEquipDirectorRequirements: '',  // (중장비) 감독 요구사항
          heavyEquipRequireDeptRequirements: '',  // (중장비)요청부서 요구사항
          heavyEquipSupportDeptRequirements: '',  // (중장비) 지원부서 요구사항
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          checkResults: [], // 안전조치항목
          gases: [], // 가스항목측정 ** 사용하지 않는 보충작업도 존재하지만 빈 배열을 할당시켜 관리
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          editFlag: 'C',
          entrants: [], // 입출입자
          deleteEntrants: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    saveAction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '450px'
      },
      grid2: {
        columns: [],
        height: '450px'
      },
      gridEnter: {
        columns: [
          {
            name: 'deptUserName',
            field: 'deptUserName',
            label: '출입자',
            align: 'center',
            sortable: false,
          },
        ],
        height: '450px'
      },
      useGasItems: [],
      editable: true,
      listCheckItemUrl: '',
      listGasItemUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '100%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'workPermit.plantCd'() {
      this.$set(this.supWork, 'checkResults', [])
      this.$set(this.supWork, 'gases', [])
      this.getCheckItemList();
      this.getGasItemList();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.listGasItemUrl = selectConfig.sop.swp.gas.list.url
      // code setting
      // list setting
      this.getCheckItemList();
      this.getGasItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '항목',
          align: 'left',
          sortable: true,
        },
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: '필요',
          align: 'center',
          sortable: true,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
        // {
        //   name: 'confirmFlag',
        //   field: 'confirmFlag',
        //   label: '확인',
        //   align: 'center',
        //   sortable: true,
        //   type: 'check',
        //   disableTarget: 'necessaryFlag',
        //   disableCon: 'Y',
        //   'true': 'Y',
        //   'false': 'N',
        //   value: 'N',
        //   setHeader: true,
        // },
      ]
      if (!this.workPermit.plantCd) return;
      if (!this.supWork.checkResults) this.supWork.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000010',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld && (!this.supWork.checkResults || this.supWork.checkResults.length === 0)) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.supWork.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'Y',  // 필요
                // confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.supWork.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.supWork.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'Y',  // 필요
                  // confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.supWork.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: '확인자',
              align: 'center',
              sortable: true,
              type: 'user',
              userId: 'confirmUserId',
              disableTarget: 'necessaryFlag',
              disableCon: 'Y',
            })
          }
        },);
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            sortable: true,
            type: 'user',
            userId: 'confirmUserId',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
          })
        }
      }
    },
    getGasItemList() {
      this.grid2.columns = [
        {
          name: 'checkTime',
          field: 'checkTime',
          label: '점검시간',
          align: 'center',
          type: 'datetime',
          minuteStep: 10,
          sortable: false,
          style: 'width:80px',
          colClass: 6,
        },
        {
          name: 'gasCheckMachine',
          field: 'gasCheckMachine',
          label: '점검기기',
          align: 'center',
          sortable: false,
          type: 'text',
          style: 'width:80px',
          colClass: 6,
        },
        {
          name: 'gasCheckUserName',
          field: 'gasCheckUserName',
          label: '점검자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasCheckUserId',
          style: 'width:80px',
          colClass: 6,
        },
        {
          name: 'gasConfirmUserName',
          field: 'gasConfirmUserName',
          label: '확인자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasConfirmUserId',
          style: 'width:80px',
          colClass: 6,
        },
      ];

      this.$http.url = this.listGasItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.workPermit.plantCd,
        // sopWorkTypeCd: 'SWT0000010',
      }
      this.$http.request((_result) => {
        this.useGasItems = [];
        if (this.supWork.gases && this.supWork.gases.length > 0) {
          let pushData = [];
          this.$_.forEach(this.supWork.gases, gas => {
            for (let gasValue in gas) {
              let idx = this.$_.findIndex(_result.data, { sopGasId: gasValue });
              let idx2 = this.$_.findIndex(pushData, { sopGasId: gasValue });
              /**
               * idx의 경우 기본적으로 존재하는지 여부를 판단하지만
               *            예전 입력된 값 중 useFlag N처리된 값이 있는 경우는 허용
               */
              if ((idx > -1 && gas[gasValue] !== '-') && idx2 === -1) {
                this.$set(_result.data[idx], 'me')
                pushData.push(_result.data[idx])
              }
            }
          })
          if (pushData && pushData.length > 0) {
            this.$_.forEach(pushData, item => {
              this.useGasItems.push(item)
              this.grid2.columns.push({
                // 가스 헤더 적용 로직 추가 필요
                name: item.sopGasId + '_p',
                field: item.sopGasId + '_p',
                label: item.gasName,
                align: 'center',
                sortable: false,
                child: [
                  {
                    name: item.sopGasId,
                    field: item.sopGasId,
                    label: item.gasName + ' [' + item.safetyStandards + ']',
                    align: 'center',
                    type: 'text',
                    sortable: false,
                    style: 'width:80px',
                    colClass: 6,
                  },
                ]
              })
            })
          }
        } else {
          let useYList = this.$_.filter(_result.data, item => {
            return item.useFlag === 'Y' && this.$_.findIndex(item.workTypes, { sopWorkTypeCd: 'SWT0000010' }) > -1
          });
          this.$_.forEach(useYList, gas => {
            this.useGasItems.push(gas)
            this.grid2.columns.push({
              // 가스 헤더 적용 로직 추가 필요
              name: gas.sopGasId + '_p',
              field: gas.sopGasId + '_p',
              label: gas.gasName,
              align: 'center',
              sortable: false,
              child: [
                {
                  name: gas.sopGasId,
                  field: gas.sopGasId,
                  label: gas.gasName + ' [' + gas.safetyStandards + ']',
                  align: 'center',
                  type: 'text',
                  sortable: false,
                  style: 'width:80px',
                  colClass: 6,
                },
              ]
            })
          })
        }
      },);
    },
    addGas() {
      let pushData = {
        sopGasCheckId: uid(),  // 가스농도측정번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId, // 작업허가서 일련번호
        supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
        checkTime: '',  // 점검시간
        gasCheckMachine: '',  // 점검기기명
        gasCheckUserName: '',
        gasCheckUserId: '',  // 점검자
        gasConfirmUserName: '',
        gasConfirmUserId: '',  // 확인자(입회자)
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      }
      this.$_.forEach(this.useGasItems, gas => {
        this.$set(pushData, gas.sopGasId, '0');
      });
      
      this.supWork.gases.push(pushData);
    },
    removeGas() {
      let selectData = this.$refs['gasTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '삭제할 행을 지정하세요.', // 삭제할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteGasChecks) this.workPermit.deleteGasChecks = [];
        if (!this.workPermit.deleteGasCheckVals) this.workPermit.deleteGasCheckVals = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.supWork.deleteGasChecks, { sopGasCheckId: item.sopGasCheckId }) === -1
            && item.editFlag !== 'C') {
            this.supWork.deleteGasChecks.push(item);
            this.$_.forEach(this.useGasItems, gas => {
              this.supWork.deleteGasCheckVals.push({
                sopGasCheckId: item.sopGasCheckId,
                sopGasId: gas.sopGasId
              })
            })
          }
          this.supWork.gases = this.$_.reject(this.supWork.gases, item);
          this.$refs['gasTable'].$refs['compo-table'].clearSelection();
        });
      }
    }, 
    addVendorEntrant() {
      this.popupOptions.title = '도급업체 근무자'; // 도급업체 근무자
      this.popupOptions.param = {
        type: 'multiple',
        vendorCd: this.workPermit.vendorCd // 도급업체코드
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorUserPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeVendorUserPopup;
    },
    closeVendorUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.supWork.entrants, { vendorUserCd: item.vendorUserCd }) === -1) {
            this.supWork.entrants.push({
              sopWorkEntrantId: uid(),  // 밀폐 입출입자 번호
              sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
              deptName: '',  // 출입자 부서
              vendorName: item.vendorName,  // 출입자 업체명
              vendorUserCd: item.vendorUserCd,  // 업체 출입자id
              userId: '',  // 출입자 id
              userName: item.personName,  // 출입자명
              deptUserName: '(' + item.vendorName + ')' + item.personName,  // 출입자명 부서포함
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        
        if (this.supWork.editFlag !== 'C') {
          this.supWork.editFlag = 'U'
          this.supWork.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    addEntrant() {
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.workPermit.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEntrantPopup;
    },
    closeEntrantPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.supWork.entrants) this.supWork.entrants = []
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.supWork.entrants, { userId: item.userId }) === -1) {
            this.supWork.entrants.push({
              sopWorkEntrantId: uid(),  // 밀폐 입출입자 번호
              sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
              deptName: item.deptName,  // 출입자 부서
              vendorName: '',  // 출입자 업체명
              vendorUserCd: '',  // 업체 출입자id
              userId: item.userId,  // 출입자 id
              userName: item.userName,  // 출입자명
              deptUserName: '(' + item.deptName + ')' + item.userName,  // 출입자명 부서포함
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        
        if (this.supWork.editFlag !== 'C') {
          this.supWork.editFlag = 'U'
          this.supWork.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    removeEntrant() {
      let selectData = this.$refs['entrantsTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.supWork.deleteEntrants) this.supWork.deleteEntrants = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.supWork.deleteEntrants, { sopWorkEntrantId: item.sopWorkEntrantId }) === -1
            && item.editFlag !== 'C') {
            this.supWork.deleteEntrants.push(item)
          }
          this.supWork.entrants = this.$_.reject(this.supWork.entrants, item);
        })
        this.$refs['entrantsTable'].$refs['compo-table'].clearSelection();
      
        if (this.supWork.editFlag !== 'C') {
          this.supWork.editFlag = 'U'
          this.supWork.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    guardianChange(userId, user) {
      if (userId) {
        this.$set(this.supWork, 'closeGuardianId', userId)
        this.$set(this.supWork, 'closeGuardianMobileNo', this.$comm.phoneConvert(user.mobileNo))
      } else {
        this.$set(this.supWork, 'closeGuardianId', '')
        this.$set(this.supWork, 'closeGuardianMobileNo', '')
      }
    },
    dataChange() {
      if (this.supWork.editFlag !== 'C') {
        this.supWork.editFlag = 'U'
        this.supWork.chgUserId = this.$store.getters.user.userId
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
    // tableDataChange(props, col) {
    //   if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
    //     this.$set(props.row, 'confirmFlag', 'N');
    //   }
    // },
  }
};
</script>
